<template>
  <header class="header">
    <!-- <a href="/" class="header__logo"><img src="@/assets/img/logo.png" alt="logo" /></a> -->
    <a href="/" class="header__logo">MPLAB</a>
    <!-- <nav class="nav">
      <template v-for="(item, index) of navigationList">
        <router-link class="nav__item" v-if="item.type === 'route'" :key="index" :to="item.link">{{
          item.title
        }}</router-link>
        <a v-else :href="item.link" class="nav__item" :key="index">{{ item.title }}</a>
      </template>
      <a href="https://app.mplab.io/" class="nav__item">Войти</a>
    </nav> -->
    <!-- <div class="header__buttons wrapper">
      <a href="https://t.me/mplabio_bot" class="header__button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10 14C8.82843 12.8284 6 12 6 12C6 12 8.82843 11.1716 10 10C11.1716 8.82843 12 6 12 6C12 6 12.8284 8.82843 14 10C15.1716 11.1716 18 12 18 12C18 12 15.1716 12.8284 14 14C12.8284 15.1716 12 18 12 18C12 18 11.1716 15.1716 10 14Z"
            fill="white"
          />
          <path
            d="M6 12H12M6 12C6 12 8.82843 12.8284 10 14C11.1716 15.1716 12 18 12 18M6 12C6 12 8.82843 11.1716 10 10C11.1716 8.82843 12 6 12 6M12 12H18M12 12V18M12 12V6M18 12C18 12 15.1716 11.1716 14 10C12.8284 8.82843 12 6 12 6M18 12C18 12 15.1716 12.8284 14 14C12.8284 15.1716 12 18 12 18"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Попробовать бесплатно
      </a>
      <a href="https://t.me/mplabio_bot" class="header__login">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99967 12.5002L12.4997 10.0002M12.4997 10.0002L9.99967 7.50016M12.4997 10.0002H3.33301M7.49967 6.04065V6.00033C7.49967 5.0669 7.49967 4.59985 7.68133 4.24333C7.84112 3.92972 8.0959 3.67494 8.40951 3.51515C8.76602 3.3335 9.23308 3.3335 10.1665 3.3335H13.9998C14.9333 3.3335 15.3993 3.3335 15.7558 3.51515C16.0694 3.67494 16.3251 3.92972 16.4849 4.24333C16.6663 4.5995 16.6663 5.06599 16.6663 5.99759V14.0031C16.6663 14.9347 16.6663 15.4006 16.4849 15.7567C16.3251 16.0703 16.0694 16.3256 15.7558 16.4854C15.3997 16.6668 14.9338 16.6668 14.0022 16.6668H10.1638C9.23217 16.6668 8.76568 16.6668 8.40951 16.4854C8.0959 16.3256 7.84112 16.0701 7.68133 15.7565C7.49967 15.4 7.49967 14.9336 7.49967 14.0002V13.9585"
            stroke="url(#paint0_linear_3643_33432)"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3643_33432"
              x1="3.33301"
              y1="16.6668"
              x2="17.441"
              y2="15.7894"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#6C29BF" />
              <stop offset="1" stop-color="#5429CD" />
            </linearGradient>
          </defs>
        </svg>
        Войти
      </a>
    </div> -->
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderLanding',
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['getPhone', 'getShowPhone', 'navigationList']),
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  background: transparent;
  // border-bottom: 1px solid rgba(58, 65, 111, 0.1);
  // backdrop-filter: blur(30px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &__logo {
    color: #fff;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
  }
  &__button {
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    padding: 9px 30px 9px 22px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s;
    path {
      transition: all 0.3s;
    }
    &:hover {
      color: #fff;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    background: rgba(108, 41, 191, 0.3);
    padding: 9px 15px;
    border-radius: 12px;
    svg {
      margin-right: 8px;
    }
  }

  &__login {
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6c29bf;
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    padding: 7px 30px;
    transition: all 0.3s;
    display: none;

    svg {
      transition: all 0.2s;
      margin-right: 5px;
      stop {
        stop-color: #fff;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
  &__buttons {
    gap: 16px;
  }
}

.nav {
  display: flex;
  gap: 30px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 14px 30px;

  &__item {
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.5s;
    &:hover {
      color: #6c29bf;
    }
    &:last-child {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      padding-left: 30px;
    }
  }
}

@media screen and (max-width: 480px) {
  .nav {
    display: none;
  }
  .header {
    justify-content: flex-start;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.1);
    &__logo {
      height: 36px;
      margin-right: auto;
      img {
        max-width: 130px;
      }
    }
    &__button {
      display: none;
    }
    &__login {
      font-size: 14px;
      display: block;
      line-height: 24px;
      padding: 7px 16px;
      border-radius: 28px;
      svg {
        display: none;
      }
    }
    &__phone {
      padding: 8px;
      border-radius: 50%;
      order: 1;
      span {
        display: none;
      }
      svg {
        margin-right: 0;
      }
    }
  }
  
}
</style>
