import axios from "axios";
import API_ENV from "@/config/api.config";

let { apiSendForm } = API_ENV;

const form = {
  namespaced: "form",
  state: {},
  getters: {},
  mutations: {},
  actions: {
    SEND_FORM({ commit }, data) {
      console.log( commit );
      return new Promise((resolve, reject) => {
        axios
          .post(apiSendForm, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};

export default form;
