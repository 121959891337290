<template lang="">
  <footer class="footer">
    <div class="wrapper footer__columns footer__mobile">
      <div class="wrapper footer__wrapper">
        <img class="footer__logo" src="@/assets/img/logo_footer.png" alt="logo" />
        <a href="https://reestr.digital.gov.ru/reestr/1765560/" target="_blank">
          <img src="@/assets/img/Footer/mincifry.png" alt="минцифры" />
        </a>
      </div>
      <p class="footer__text">
        Автоматизируйте продажи на Wildberries и увеличивайте доход в 2 раза
      </p>

      <div class="wrapper footer__social">
        <a :href="item.link" v-for="(item, index) of socialList" :key="index">
          <img :src="item.img" alt="social" />
        </a>
      </div>
      <div class="footer__contacts">
        <a
          class="footer__contacts-item"
          v-for="(item, index) of contacts"
          :key="index"
          :href="item.link"
          >{{ item.title }}</a
        >
      </div>
      <!-- <a href="#bottom-form" class="button footer__button">Попробовать бесплатно</a> -->

      <div class="wrapper footer__payment">
        <img :src="item.img" alt="icon" v-for="(item, index) of paymnetList" :key="index" />
      </div>

      <a
        v-for="(item, index) of infoList"
        :key="index"
        :href="item.link"
        class="footer__text footer__link"
        >{{ item.title }}</a
      >

      <p class="footer__desc">© 2023 ООО «МПЛАБ»</p>
      <div class="wrapper footer__requisites">
        <p class="footer__desc">ИНН: 9701184440</p>
        <p class="footer__desc">ОГРН: 1217700434566</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterSection",
  data() {
    return {
      contacts: [],
      infoList: [
        {
          title: "Политика конфиденциальности",
          link: "/docs/privacy.pdf",
        },
        {
          title: "Договор оферты",
          link: "/docs/legal.pdf",
        },
        {
          title: "Политика оплаты и возврата",
          link: "/docs/policy.pdf",
        },
        {
          title: "Свидетельство о государственной регистрации",
          link: "/docs/reg.pdf",
        },
        {
          title: "Описание функциональных характеристик ПО",
          link: "/docs/Описание_функциональных_характеристик_ПО.pdf",
        },
      ],
      paymnetList: [
        {
          img: require("@/assets/img/Footer/payment/visa.svg"),
        },
        {
          img: require("@/assets/img/Footer/payment/mastercard.svg"),
        },
        {
          img: require("@/assets/img/Footer/payment/mir.svg"),
        },
      ],
      socialList: [
        // {
        //   img: require("@/assets/img/Footer/social/vk.svg"),
        //   link: "https://vk.com/mplabio",
        // },
        {
          img: require("@/assets/img/Footer/social/tg.svg"),
          link: "https://t.me/mamod_ai",
        },
        {
          img: require("@/assets/img/Footer/social/yt.svg"),
          link: " https://www.youtube.com/@mamodai",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getPhone", "getShowPhone"]),
  },
  created() {
    this.contacts = [
      // {
      //   title: this.getShowPhone,
      //   link: `tel:${this.getPhone}`,
      // },
      {
        title: "info@mplab.io",
        link: `mailto:info@mplab.io`,
      },
    ];
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background: #0b1724;
  padding-top: 60px;
  padding-bottom: 80px;


  &__mobile {
    display: none;
  }

  &__columns {
    align-items: flex-end;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    margin: 23px 0 30px;
    gap: 10px;

    &-item {
      font-weight: 500;
      font-size: 15px;
      line-height: 135%;
      color: #ffffff;
      text-decoration: none;
    }
  }

  &__button {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-decoration: none;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(86.44deg, #6c29bf 0%, #5429cd 100%);
    box-shadow: 20px 20px 60px rgba(89, 41, 202, 0.4);
    border-radius: 6px;
    padding: 20px 0;
    margin-top: 26px;
  }

  &__nav {
    gap: 90px;

    &-item {
      font-weight: 500;
      font-size: 15px;
      text-decoration: none;
      color: #ffffff;
      margin-bottom: 12px;
      line-height: 135%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    width: 143px;
    margin-bottom: 15px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: #ffffff;
    max-width: 280px;
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    text-decoration: none;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__payment {
    justify-content: flex-start;
    margin: 20px 0;
    gap: 10px;
  }

  &__desc {
    font-weight: 500;
    font-size: 12px;
    line-height: 135%;
    color: rgba(255, 255, 255, 0.5);
  }

  &__company {
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    color: rgba(255, 255, 255, 0.6);
    margin-top: auto;
  }

  &__requisites {
    margin-top: 10px;
  }

  &__social {
    justify-content: flex-start;
    gap: 22px;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    &__mobile {
      padding-top: 0;
      padding-bottom: 0;
      padding: 0;
      align-items: center;
      justify-content: center;
    }

    &__columns {
      display: none;
    }

    &__mobile {
      display: flex;
    }

    &__social {
      flex-direction: row;
    }

    &__contacts {
      margin: 16px 0 24px;
    }

    &__button {
      margin-top: 0;
      width: 100%;
      font-size: 15px;
      line-height: 22px;
    }

    .wrapper {
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      &.footer__social {
        flex-direction: row;
        justify-content: center;
      }

      &.footer__payment {
        flex-direction: row;
        margin: 20px 0 30px;
        justify-content: center;
      }
    }

    &__column {
      align-items: center;
    }

    &__logo {
      margin-bottom: 10px;
    }

    &__text {
      font-size: 14px;
      line-height: 135%;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 24px;
    }

    &__link {
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 14px;
      font-size: 14px;
      line-height: 135%;
    }

    &__company {
      margin-top: 22px;
      font-size: 14px;
      line-height: 135%;
    }
  }
}
</style>
