import Vue from "vue";
import Vuex from "vuex";
import form from "./modules/form";
import podeli from "./modules/podeli";
import utmButtons from "./modules/utmButtons";
import thanks from "./modules/thanks";
import rates from "./modules/rates";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    phone: "+78001014271",
    showPhone: "+7 (800) 101-42-71",
    navigationList: [],
  },
  getters: {
    getPhone: ({ phone }) => phone,
    getShowPhone: ({ showPhone }) => showPhone,
    navigationList: ({ navigationList }) => navigationList,
  },
  mutations: {
    EDIT_NAV_LIST(state, data) {
      state.navigationList = data;
    },
  },
  actions: {
    resetNavList({ commit }) {
      commit("EDIT_NAV_LIST", [
        // {
        //   title: "Инструменты",
        //   link: "/#tools",
        // },
        // {
        //   title: "Миссия",
        //   link: "/#mission",
        // },
        // {
        //   title: "Поддержка",
        //   link: "/#support",
        // },
        // {
        //   title: "Процесс",
        //   link: "/#process",
        // },
        // {
        //   title: "FAQ",
        //   link: "/#faq",
        // },
        {
          title: "Тарифы",
          type: "route",
          link: "/rates",
        },
      ]);
    },
    editNavList({ state, commit }) {
      const newList = state.navigationList.map((item) => {
        item.type = "route";
        if (item.link.slice(0, 1) != "/") {
          item.link = `/${item.link}`;
        }
        return item;
      });
      commit("EDIT_NAV_LIST", newList);
    },
  },
  modules: {
    form,
    podeli,
    utmButtons,
    thanks,
    rates,
  },
});
