
const podeli = {
  namespaced: 'podeli',
  state: {
    isPopupVisible: true,
    isModalVisible: false,
  },
  getters: {
    isPopupVisible: ({isPopupVisible}) => isPopupVisible,
    isModalVisible: ({isModalVisible}) => isModalVisible,

  },
  mutations: {
    CHANGE_POPUP_VISBLE(state){
      state.isPopupVisible = !state.isPopupVisible
    },
    CHANGE_MODAL_VISBLE(state){
      state.isModalVisible = !state.isModalVisible
    },
  },
  actions: {
    CHANGE_POPUP_VISBLE({commit}){
      commit('CHANGE_POPUP_VISBLE')
    },
    CHANGE_MODAL_VISBLE({commit}){
      commit('CHANGE_MODAL_VISBLE')
    }

  },
}

export default podeli