const API = process.env.VUE_APP_URL;

const API_ENV = {
  apiSendForm: `${API}landing/`,
  apiEditData: `${API}landing/`,

  /**
   * Rates - Тарифы
   */
  // Получение тарифов
  apiRates: `${API}billing/tariffs/`,
  // Оплата тарифов
  apiCreateOrder: `${API}billing/tariffs/orders/`,
  apiProlongateOrder: `${API}billing/tariffs/prolongation/`,
  apiCalculateOrder: `${API}billing/tariffs/orders/calculated/`,
  // Перед страницей покупки
  apiPreorderPay: `${API}billing/tariffs/pay/`,
  apiUsePromocode: `${API}billing/tariffs/promocode/`,
  apiSendOrder: `${API}billing/tariffs/pay/payment-link/`,

};

export default API_ENV;
