import axios from "axios"
import API_ENV from '@/config/api.config'

let { apiSendForm } = API_ENV

const thanks = {
  namespaced:'thanks',
  state: {
  },
  getters: {
  },
  mutations: {
    
  },
  actions: {
    async SEND_DATA({ commit }, data) {
      console.log(commit);
      const response = await axios.patch(`${apiSendForm}${data.id}/activate-bonus`)
        .then(() => {
          window.open(`https://itcreation.io/w/bX4f?utm_source=website&utm_medium=button&utm_campaign=spasibo`) 
        })
      console.log(response);
    }
  },
}
export default thanks