<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer/Footer.vue';
import router from './router';
import { mapActions, mapGetters } from 'vuex';
router;
export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    ...mapActions(['resetNavList', 'editNavList']),
  },
  updated() {
    router.beforeEach((to, from, next) => {
      if (to.name !== from.name) {
        if (to.name !== 'home') {
          this.editNavList();
          next();
        } else {
          this.resetNavList();
          next();
        }
      }
      return;
    });
  },
  mounted() {
    this.resetNavList();
  },
  computed: {
    ...mapGetters(['navigationList']),
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SF UI';
}
html {
  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background: #1c202d;
}

.wrapper {
  display: flex;
}

.button {
  cursor: pointer;
  border: none;
  &:disabled,
  &[disabled='disabled'] {
    opacity: 0.8;
    cursor: not-allowed !important;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}
small {
  opacity: 1 !important;
}

section,
footer {
  padding: 0 calc(50% - 650px);
}
@media (min-width: 319.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 156px);
  }
}

@media (min-width: 575.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 270px);
  }
}

@media (min-width: 767.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 360px);
  }
}

@media (min-width: 991.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 480px);
  }
}

@media (min-width: 1199.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 600px);
  }
}
@media (min-width: 1599.98px) {
  section,
  header,
  footer {
    padding: 0 calc(50vw - 830px);
  }
}


</style>
