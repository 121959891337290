import axios from "axios";
import API_ENV from "@/config/api.config";

let {
  apiRates,
  apiCreateOrder,
  apiSendOrder,
  apiProlongateOrder,
  apiPreorderPay,
  apiCalculateOrder,
} = API_ENV;

export default {
  state: {
    chosenRate: "",
  },
  mutations: {
    CHANGE_CHOSEN_RATE(state, rate) {
      state.chosenRate = rate;
    },
  },
  getters: {
    getChosenRate: ({ chosenRate }) => chosenRate,
  },
  actions: {
    changeChosenRate({ commit }, data) {
      commit("CHANGE_CHOSEN_RATE", data);
    },
    getRatesInfo({commit}) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiRates)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            commit("showAlert");
            reject(err);
          });
      });
    },
    getOrderInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(apiPreorderPay)
          .then((response) => resolve(response.data))
          .catch((err) => {
            commit("showAlert");
            reject(err);
          });
      });
    },

    createOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.isProlongate) {
          axios
            .post(apiProlongateOrder, { period: data.transmitData.period })
            .then((response) => resolve(response))
            .catch((err) => {
              commit("showAlert");
              reject(err);
            });
        } else {
          axios
            .post(apiCreateOrder, data.transmitData)
            .then((response) => resolve(response))
            .catch((err) => {
              commit("showAlert");
              reject(err);
            });
        }
      });
    },
    createCalculateOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiCalculateOrder, data)
          .then((response) => resolve(response))
          .catch((err) => {
            commit("showAlert");
            reject(err);
          });
      });
    },

    sendOrder({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(apiSendOrder, data)
          .then((response) => resolve(response))
          .catch((err) => {
            commit("showAlert");
            reject(err);
          });
      });
    },
  },
};
