import axios from "axios"
import API_ENV from '@/config/api.config'

let { apiSendForm } = API_ENV


const utmButtons = {
  namespaced: 'utmButtons',
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async sendParams({ commit }, utm) {
      console.log(commit);
      const response = await axios.post(apiSendForm, utm)
        .then((response) => {
          console.log(response);
          // window.open(data.utm)
        })
        .catch(error=>{
          console.log(error);
        })
      console.log(response);
    }

  },
}

export default utmButtons